
import { useParams, Redirect, useLocation } from "react-router-dom";

import { useEffect } from "react";

//import usePretty from "../../hooks/usePretty";
import useScrollToTop from "../../hooks/useScrollToTop";

import options from '../../data/oldbailey/options';
import options_toc from '../../data/oldbailey/options_toc';

import PostWithToc from "./PostWithToc";

//import oldBaileyLookups from '../../data/oldbailey/lookups';

const About = (props) => {

    useScrollToTop();

    const { slug } = useParams();

    //const [ pretty, ] = usePretty(oldBaileyLookups);

    //document.title = pretty(slug) + " | About | The Proceedings of the Old Bailey";

    // Bootstrap ScrollSpy seems not to work on asynchronously added content.
    // Therefore, below is my basic reimplementation of ScrollSpy.
    useEffect(() => {

        const handleScroll = () => {

            const h2s = document.querySelectorAll("h2");

            const as = document.querySelectorAll("div#post-toc ul li a");

            let current = null;

            h2s.forEach( (h2, i) => {

               if (window.scrollY > (h2.offsetTop - 200)) {

                   current = h2.id;
               }

               if (!current) { current = 'top' }

               if (current) {

                    as.forEach( (a, i) => {

                       if (a.href) {

                           const parts = a.href.split('#');

                           if (parts[1]) {

                               if (parts[1] === current) {

                                   a.className = 'active';

                               } else {

                                   a.className = '';

                               }

                           }

                       }

                    });

               }

            });

            /*
            const pt = document.querySelector("div#post-toc");

            var from_end = ((document.body.scrollHeight - window.innerHeight) - window.scrollY);

            console.log(from_end);

            if (from_end > 2000) {

                pt.style.display = 'inline';
                pt.style.opacity = 1.0;

            } else if (from_end < 2000) {

                pt.style.display = 'inline';
                pt.style.opacity = (from_end - 1000) / 1000;

            } else if (from_end < 1000) {

                pt.style.display = 'none';

            }
            */

        };
  
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };

    }, []);

    // On this page, we need to redirect URLs which end in a slash to remove the slash
    // Otherwise, internal links from this page will break.
    const location = useLocation();

    return (

        <>

            { location.pathname.endsWith('/') && (

                <Redirect to={ location.pathname.slice(0, -1) + location.hash } />

            ) }

            <div className="container calc-vh mt-5">

                <PostWithToc url={ `${process.env.REACT_APP_WORDPRESS_URL}/posts?slug=` + slug } options={ options } options_toc={ options_toc } />                  

            </div>

            {/* 
            <div className="container calc-vh mt-5">
                <div className="row">
                    <div className="col-7">
                    <div className="ob-about-page ob-body-text"><div data-bs-spy="scroll" data-bs-target="#post-toc" data-bs-smooth-scroll="true" className="scrollspy-example" tabIndex="0"><h1>Associated Records</h1><div className="bad"><div id="attachment_4470" className="wp-caption aligncenter"><img className="img-fluid" srcSet="https://www.dhi.ac.uk/blogs/old-bailey/wp-content/uploads/sites/35/2022/03/Sandby-Last-dying-speeches-259x300.jpg 259w, https://www.dhi.ac.uk/blogs/old-bailey/wp-content/uploads/sites/35/2022/03/Sandby-Last-dying-speeches-885x1024.jpg 885w, https://www.dhi.ac.uk/blogs/old-bailey/wp-content/uploads/sites/35/2022/03/Sandby-Last-dying-speeches-768x889.jpg 768w, https://www.dhi.ac.uk/blogs/old-bailey/wp-content/uploads/sites/35/2022/03/Sandby-Last-dying-speeches-1327x1536.jpg 1327w, https://www.dhi.ac.uk/blogs/old-bailey/wp-content/uploads/sites/35/2022/03/Sandby-Last-dying-speeches-1769x2048.jpg 1769w, https://www.dhi.ac.uk/blogs/old-bailey/wp-content/uploads/sites/35/2022/03/Sandby-Last-dying-speeches-624x722.jpg 624w" src="http://www.dhi.ac.uk/blogs/old-bailey/wp-content/uploads/sites/35/2022/03/Sandby-Last-dying-speeches-259x300.jpg"/><p id="caption-attachment-4470" className="wp-caption-text">Paul Sandby, “Last Dying Speeches” (c.1759). © Nottingham Castle Museum and Art Gallery</p></div></div>
                    <h2 className="subtitle">Other Books and Documents about the Crimes Tried at the Old Bailey</h2>
                    <p className="intro">The accounts of trials in the <strong>Proceedings</strong> provide the most accessible information available about the crimes tried at the Old Bailey, but they often represent only the tip of an iceberg of surviving evidence about the case. This page describes some of the other richly detailed sources available. They can be consulted to find more information about the circumstances of the case, the individuals involved, and their previous and subsequent encounters with criminal justice.</p>
                    <p className="intro">This website provides access to these records in two ways: 1) For trials which took place between 1674 and October 1834, links are provided from individual trials to records included in the <strong>Associated Records Database</strong> (comprising 25,000 entries, which can also be <a href="https://www.oldbaileyonline.org/forms/formAssociatedRecords.jsp">searched</a> separately).&nbsp; 2) For defendants convicted between 1740 and 1913, it provides links from their trials to records in the <strong><a href="http://digitalpanopticon.org">Digital Panopticon</a></strong> web resource.</p>
                    <p>It should be noted, however, that these databases are not comprehensive. Researchers seeking a full account of particular individuals or crimes may need to consult other sources not discussed here.</p>
                    <h2 className="divider subtitle">Contents of this Article</h2>
                    <ul>
                    <li><a href="#popularliterature">Introduction: Crime Literature and Judicial Records</a></li>
                    <li><a href="#lastdyingspeeches">Last Dying Speeches</a></li>
                    <li><a href="#biographies">Criminal Biographies</a></li>
                    <li><a href="#compilations">Compilations of Trials</a></li>
                    <li><a href="#newspapers">Newspapers</a></li>
                    <li><a href="#courtrecords">Court Records</a></li>
                    <li><a href="#pardons">Petitions and Pardons</a></li>
                    <li><a href="#calendarsofprisoners">Calendars of Prisoners</a></li>
                    <li><a href="#refuge">Refuge for the Destitute</a></li>
                    <li><a href="#punishment">Punishment Records</a></li>
                    <li><a href="#literature">Novels, Satirical Prints and Popular Entertainment</a></li>
                    <li><a href="#using">Using the Associated Records 1674-1834 Database</a></li>
                    <li><a href="#libraries">Locations of Documents Included in the Associated Records Database</a></li>
                    <li><a href="#digitalpanopticon">Records in the Digital Panopticon, 1740 to 1925</a></li>
                    <li><a href="#reading">Introductory Reading</a></li>
                    </ul>
                    <p><a href="https://www.dhi.ac.uk/obp-new/search/associated_records">Search the Asssociated Records 1674-1834 Database</a></p>
                    <h2 id="popularliterature" className="divider">Introduction: Crime Literature and Judicial Records</h2>
                    <p>With rising literacy, combined with growing concern about crime, demand for literature about crime skyrocketed in the late seventeenth century, even before the expiration of press licensing in 1695 made such publications easier to produce. Stories about crime seemed to address many of the insecurities of the age, and not just fears about crime. Printed ballads, periodicals, reports of crimes and of apprehending criminals, pamphlets taking the side of defendants or prosecutors, <a href="/data/about/linked-records/#lastdyingspeeches">last dying speeches</a>, accounts of executions, and <a href="/data/about/ordinarys-accounts/">Ordinary of Newgate’s Accounts</a>, all became popular in the same years that the genre of trial reporting developed with the advent of the <strong>Old Bailey Proceedings</strong>. By the early eighteenth century, these genres had contributed to the development of <a href="/data/about/linked-records/#biographies">criminal biographies</a>, <a href="/data/about/linked-records/#literature">the novel and the satirical print</a>. By comparing accounts of the same trial in these different publications, one can discover additional details about the case and also identify the many different ways in which crime was perceived and interpreted in this period.</p>
                    <p>Over the course of the eighteenth century the market for this literature evolved. In addition to the <strong>Proceedings</strong>, separate accounts of individual trials and <a href="/data/about/linked-records/#compilations">selected collections of trials</a> were published. The provision of detailed information about criminal cases in <a href="/data/about/linked-records/#newspapers">newspapers</a> and trial reports meant that the more sensationalist and didactic forms of this literature became increasingly implausible, as readers could compare the exaggerated and moralistic tales offered with the detailed accounts they read in other sources. Changing standards of morality led to a silencing of the more lurid sexual details found in early publications. Consequently, by the 1770s some of the more didactic and racy publications were in decline.</p>
                    <p>In the nineteenth century, mass literacy and the rapid expansion of the newspaper press led to an increasing volume of reporting on criminal trials, though coverage was increasingly selective and sensationalised.&nbsp; <a href="#literature">Novels and other forms of popular entertainment</a>, often focusing on the most violent and spectacular forms of crime, also reached a wide audience.</p>
                    <p>Throughout this period, the courts kept a wide range of manuscript records concerning criminal cases, including examinations of witnesses, calendars of prisoners, petitions, and registers of punishments.&nbsp; Eighteenth-century records were typically restricted to the details of crimes and judicial processes, but nineteenth-century records increasingly provided considerable personal information about the accused, including their physical characteristics, background, and character.</p>
                    <h2 id="lastdyingspeeches">Last Dying Speeches</h2>
                    <div className="center image"></div>
                    <div>
                    <div className="bad"><div id="attachment_5247" className="wp-caption alignnone"><img className="img-fluid" srcSet="https://www.dhi.ac.uk/blogs/old-bailey/wp-content/uploads/sites/35/2022/03/Hogarths-Tyburn-BM-1-300x179.jpg 300w, https://www.dhi.ac.uk/blogs/old-bailey/wp-content/uploads/sites/35/2022/03/Hogarths-Tyburn-BM-1-768x457.jpg 768w, https://www.dhi.ac.uk/blogs/old-bailey/wp-content/uploads/sites/35/2022/03/Hogarths-Tyburn-BM-1-1536x914.jpg 1536w, https://www.dhi.ac.uk/blogs/old-bailey/wp-content/uploads/sites/35/2022/03/Hogarths-Tyburn-BM-1-2048x1219.jpg 2048w" src="http://www.dhi.ac.uk/blogs/old-bailey/wp-content/uploads/sites/35/2022/03/Hogarths-Tyburn-BM-1-300x179.jpg"/><p id="caption-attachment-5247" className="wp-caption-text">Finished drawing for Pl 11 of “Industry and Idleness” (1747), “The idle ‘prentice executed at Tyburn” Trustees of the British Museum</p></div></div>
                    </div>
                    <p>Following the commemoration of Protestant victims of the Marian persecutions in<a href="https://www.dhi.ac.uk/foxe/"> John Foxe’s <strong>Book of Martyrs</strong></a> (1563), the right of the condemned to speak before their execution (which had been denied to these “martyrs”) was celebrated in English society. The spiritual state of those facing death was widely believed to possess a special truth, even when their misdeeds merited death, and the public wanted to learn about their words and actions as they approached death. The right to make a “last dying speech” was publicised at the executions for treason in the 1660s and 1670s, and taken up by those convicted of ordinary crimes.</p>
                    <p>So that the condemned criminal could face God with a clear conscience, convention dictated that convicts should make a full confession and repent their sins in their speeches at the gallows. These speeches were often published as short pamphlets or broadsheets, often including accounts of the behaviour of the condemned at their execution, and some of this material was also reported in the <a href="/data/about/ordinarys-accounts/">Ordinary’s Accounts</a>. The reliability of these reports is questionable, however, since some were published before the execution even took place, as appears in plate 11 of William Hogarth’s <strong>Industry and Idleness</strong> (1747), where prominent in the foreground of the image is a woman who is already selling “The Last Dying Speech &amp; Confession of Tho. Idle”.</p>
                    <div className="center image"></div>
                    <p>In addition to preparing criminals to meet their maker, these speeches arguably served to legitimate the criminal justice system to the spectators, since the speakers typically accepted the legitimacy of their conviction and sentence of death. But convicts did not always follow the expected conventions. Some, while confessing their sins, claimed they were innocent of the crime of which they had been convicted, and others complained of injustices in the way their case had been handled. In these ways at least some dissenting voices were heard.</p>
                    <p>These broadsides began to disappear in the early nineteenth century, owing to the appearance of new forms of <a href="#literature">popular entertainment,</a> the growth of newspaper reporting and, from the 1830s, the declining number of executions. By the time of the last public execution in London, that of the Fenian <a href="https://www.oldbaileyonline.org/browse.jsp?id=def5-412-18680406&amp;div=t18680406-412">Michael Barrett</a> in May 1868, the last dying speech broadside had given way to the newspaper report.</p>
                    <h2 id="biographies">Criminal Biographies</h2>
                    <p>One of the most popular genres of criminal literature in the eighteenth century was the criminal biography. Ostensibly such books were meant to teach moral lessons by illustrating the slippery slope by which lack of religiosity, or engaging in any type of vice would invariably lead to a life of crime. They also purported to warn readers about the tricks criminals used to rob and defraud them (though there was concern that readers with a criminal inclination could also learn a few techniques). But the entertaining tone and the titillating detail of some of these books betrayed the fact that they were more often a form of entertainment than of moral instruction.&nbsp; Accounts of highway robbers, for example, often celebrated the polite treatment of their victims and their gentlemanly lifestyles.</p>
                    <p>Publishers seeking material frequently visited Newgate Prison looking for notorious criminals willing to sell their stories. Some attracted more than one biography, and some of the biographies went through multiple editions. Many were subsequently assembled into volumes of collected biographies. Some of these publications purported to be autobiographies. While this is questionable, in the sense that it is likely that the texts were heavily edited by publishers, the fact that publishers consistently traded on the “authenticity” of these accounts meant that the voice of the criminal had in some degree to be reproduced.</p>
                    <p>A particular subset of this genre, which emphasised the religious and moral dimensions of these stories, was the <a href="/data/about/ordinarys-accounts/">Ordinary of Newgate’s Accounts</a> of the lives of convicts executed at Tyburn.</p>
                    <p>During the late eighteenth century, owing to <a href="/data/about/patterns-of-crime-and-prosecution/#contemporary">changing understandings of the causes of crime</a>, respectable readers’ interest in the lives of ordinary criminals declined. The Ordinary’s Accounts ceased publication, and criminal biographies were largely restricted to elite criminals (such as forgers) or those whose crimes involved elite victims (such as <a className="urllink" href="https://www.oldbaileyonline.org/browse.jsp?div=t17900708-1" rel="nofollow">Rhynwick Williams</a>, the “London Monster”). This continued to be the case into the nineteenth century. However, especially towards the end of the nineteenth and beginning of the twentieth centuries, a number of middle-class, well-educated offenders – white-collar criminals, Fenians and suffragettes – published noteworthy accounts of their “crimes”, trials and imprisonments.</p>
                    <h2 id="compilations">Compilations of Trials</h2>
                    <p>In 1714 Captain Alexander Smith published the first collection of criminal biographies, <strong>The History of the Lives of the Most Noted Highwaymen</strong>. While this did not rely on trial accounts from the <strong>Proceedings</strong>, it was perhaps inevitable that publishers would also begin to republish the most interesting trial accounts from the <strong>Proceedings</strong> along with biographies in collected volumes. The first was published in 1718-20, and new collections of <strong>Select Trials</strong> from the Old Bailey were published in 1734-35, 1742, and 1764. The early editions (up to and including 1742) are particularly useful as they often provide more complete transcripts of the trials than was published in the original <strong>Proceedings</strong>.</p>
                    <p>The trial accounts published in these compilations were often complemented by text taken from other sources, including the <a href="/data/about/ordinarys-accounts/">Ordinary’s Accounts</a>, <a href="/data/about/linked-records/#lastdyingspeeches">last dying speeches</a>, and <a href="/data/about/linked-records/#biographies">criminal biographies</a>, including Smith’s <strong>Lives</strong>. Some editions of the <strong>Select Trials</strong> rely heavily on the more colourful language of the <strong>Ordinary’s Accounts</strong> rather than the increasingly staid <strong>Proceedings</strong>, but <a href="/data/about/ordinarys-accounts/#decline">the decline of the Ordinary’s Accounts</a> meant that from 1764 the <strong>Proceedings</strong> were the primary source for the text. In the 1760s and 1770s, as the <strong>Proceedings</strong> grew longer and more comprehensive, readers increasingly relied on publishers to select the most interesting trials for publication in these collections.&nbsp; This was particularly true of the misleadingly titled <strong>Newgate Calendars </strong>(which were in fact collections of trial accounts).&nbsp; The first edition was published in 1773 and it was reissued (with additional cases) periodically throughout the nineteenth and into the twentieth centuries. If one traces the story of a notorious criminal like the murderess <a className="urllink" href="https://www.oldbaileyonline.org/browse.jsp?div=t17260420-42" rel="nofollow">Catherine Hayes</a> (executed in 1726) through these successive compilations, one can see the story edited to reflect the changing values of the times, with the focus increasingly on its entertainment value and the moral lessons involved.</p>
                    <p>Needless to say, the selection of trials included in these compilations focused on the most sensational cases.&nbsp; Crimes such as murder, robbery, forgery and sexual offences were overrepresented (compared to the <strong>Proceedings</strong>), as were cases which resulted in convictions and executions.&nbsp; Ordinary theft cases were much less likely to be included. As a result, cases with male defendants were overrepresented.</p>
                    <p>Accounts of Old Bailey trials in the <strong>Select Trials</strong> (1742-43), <strong>Lives of the Most Remarkable Criminals</strong> (1735), <strong>The Bloody Register</strong> (1764), and <strong>Tyburn Chronicle</strong> (1768) have been included in the <strong><a className="urllink" href="https://www.oldbaileyonline.org/forms/formAssociatedRecords.jsp" rel="nofollow">Associated Records</a> Database.</strong></p>
                    <h2 id="newspapers">Newspapers</h2>
                    <div className="center image">
                    <div className="bad"><div id="attachment_8146" className="wp-caption aligncenter"><img className="img-fluid" srcSet="https://www.dhi.ac.uk/blogs/old-bailey/wp-content/uploads/sites/35/2022/03/the-morning-newsa-224x300.jpg 224w, https://www.dhi.ac.uk/blogs/old-bailey/wp-content/uploads/sites/35/2022/03/the-morning-newsa-624x837.jpg 624w" src="http://www.dhi.ac.uk/blogs/old-bailey/wp-content/uploads/sites/35/2022/03/the-morning-newsa-224x300.jpg"/><p id="caption-attachment-8146" className="wp-caption-text">William Merritt Chase, “Woman Reading the Newspaper” (1886).</p></div></div>
                    </div>
                    <p>From the early eighteenth century, an increasing number of daily, semi-weekly, and weekly newspapers were published in London, and over the course of the century these provided more and more local London news, including reports of crimes, trials, and punishments. There were reports of serious and threatening crimes which had recently occurred (especially murders and highway robberies); accounts of criminal suspects who had been apprehended; accounts of trials at the Old Bailey; and accounts of executions, especially those of notorious criminals or involving rare punishments such as burning at the stake. These reports were not systematic, but they do represent a useful additional source of information, from a non-judicial perspective.</p>
                    <p>By the late eighteenth century these reports became more extensive, undermining the commercial viability of the <strong>Proceedings</strong>, since the newspaper accounts were invariably published first. Nonetheless, except perhaps for some notorious cases, newspaper reports of trials were rarely as comprehensive as those provided in the <strong>Proceedings</strong>.&nbsp; Where the press reports are most useful, particularly in the nineteenth century, is in their increasing provision of descriptive information about the accused and witnesses, together with their reactions and responses in court.</p>
                    <p>Owing to technological innovation and the abolition of duties, newspapers became more affordable in the second half of the nineteenth century.&nbsp; Their circulation dramatically increased as new weekly and Sunday titles supplemented the dailies, and the papers became the most important source of information about crime consumed by the public.&nbsp; Titles like the <strong>Illustrated Police News</strong> (founded in 1864) reinforced a growing trend towards sensationalist reporting.&nbsp; Coverage nevertheless remained selective, even in a major title like <strong>The Times</strong>.&nbsp; In the last three full years of the publication of the <strong>Proceedings</strong> (1910-1912),&nbsp;<strong>The Times</strong> reported on only 14.4 percent of the cases recorded in the <strong>Proceedings</strong>.</p>
                    <p>Newspaper reports have not been included in the <strong>Associated Records Database,</strong> but four major digitised collections (available by subscription) provide searchable access to a huge volume of eighteenth- and nineteenth-century crime reporting: <a href="https://www.gale.com/intl/primary-sources/historical-newspapers">Gale Primary Sources</a>, the <a href="https://www.britishnewspaperarchive.co.uk/">British Newspaper Archive</a>, <a href="https://search.findmypast.co.uk/search/british-newspapers">Findmypast</a>, and <a href="https://www.newspapers.com/">Newspapers.com</a>. Some individual titles can be accessed through resources such as the <a href="https://www.gale.com/intl/c/the-times-digital-archive">Times Digital Archive</a>.&nbsp; Free access to some of these resources can be obtained in the <a href="https://www.bl.uk/?ns_campaign=brand&amp;ns_mchannel=ppc&amp;ns_source=google&amp;ns_linkname=British%20library&amp;ns_fee=0&amp;gclid=Cj0KCQjwj7CZBhDHARIsAPPWv3dFf7JWZMyMVmUKAL_bkcfL_7ZNEWRPb120f--3lO-faTtA7VQ1jzoaArxcEALw_wcB">British Library</a> and other public libraries.</p>
                    <h2 id="courtrecords">Court Records: Examinations, Depositions, Informations, Prosecution Briefs</h2>
                    <div className="center image">
                    <div className="bad"><div id="attachment_5239" className="wp-caption aligncenter"><img className="img-fluid" srcSet="https://www.dhi.ac.uk/blogs/old-bailey/wp-content/uploads/sites/35/2022/03/Everett-Information-1770-scaled-e1664194120811-177x300.jpg 177w, https://www.dhi.ac.uk/blogs/old-bailey/wp-content/uploads/sites/35/2022/03/Everett-Information-1770-scaled-e1664194120811-605x1024.jpg 605w, https://www.dhi.ac.uk/blogs/old-bailey/wp-content/uploads/sites/35/2022/03/Everett-Information-1770-scaled-e1664194120811-768x1300.jpg 768w" src="http://www.dhi.ac.uk/blogs/old-bailey/wp-content/uploads/sites/35/2022/03/Everett-Information-1770-scaled-e1664194120811-177x300.jpg"/><p id="caption-attachment-5239" className="wp-caption-text"><a href="https://www.londonlives.org/browse.jsp?id=LMOBPS45015_n18-1&amp;div=LMOBPS45015PS450150006">Ann Everett’s information against William Moody and his confederates, who stood trial at the Old Bailey on 17 January 1770</a></p></div></div>
                    </div>
                    <p>When victims reported serious crimes, and suspected felons were arrested, Justices of the Peace were required to examine the parties and witnesses and make written records of these examinations. These records often formed the basis of the evidence presented to the grand jury, and subsequently at the trial. When trials were conducted by lawyers these preliminary examinations were also incorporated into prosecution briefs prepared before the trial took place. Other legal documents produced in the course of a trial include letters, affidavits, and transcripts.</p>
                    <p>A remarkable number of these manuscript documents have been preserved, and many provide extensive additional details of the circumstances surrounding crimes as well as the judicial history of prosecutions. Most are located at <a className="urllink" href="http://www.nationalarchives.gov.uk/" rel="nofollow">The National Archives</a> and the <a href="https://www.cityoflondon.gov.uk/things-to-do/history-and-heritage/london-metropolitan-archives">London Metropolitan Archives;</a> many of the latter’s holdings have been digitised and are available on the <a href="https://www.londonlives.org/static/PS.jsp#toc10">London Lives</a> website.</p>
                    <p>Relevant records from these archives for the period from 1674 to 1834 are listed in the <strong>Associated Records Database</strong>, but survival is patchy. Few records survive after 1790, with the exception of some City cases from parts of 1818, 1825, 1828, and 1833, and Middlesex cases from 1792, 1796, 1800-12, and 1817-34.</p>
                    <p>Depositions taken for offences tried at the Old Bailey since 1839 have only been selectively preserved. Those for most of the major offences (e.g. murder, sedition, treason, riot and political conspiracy), together with a 2% random sample of other trials, are preserved in document series <a href="https://discovery.nationalarchives.gov.uk/details/r/C5513">CRIM 1</a> in The National Archives.</p>
                    <p>For cases <a href="/data/about/prosecuting-crime/#state">prosecuted by state officials</a>, including the Treasury Solicitor, Attorney General and, from 1879, the Director of Public Prosecutions, prosecution briefs and related papers can be found in The National Archives, notably in document series <a href="https://discovery.nationalarchives.gov.uk/details/r/C14079">TS11</a>, <a href="https://discovery.nationalarchives.gov.uk/details/r/C14104">TS 36</a>, <a href="https://discovery.nationalarchives.gov.uk/details/r/C6232">DPP 1</a>, and <a href="https://discovery.nationalarchives.gov.uk/details/r/C6235">DPP 4</a>. These include some full transcripts of particularly important trials, such as <a href="https://discovery.nationalarchives.gov.uk/details/r/C7869948">TS 11/1031/4431</a>, a 187 page manuscript of the <a href="https://www.oldbaileyonline.org/browse.jsp?div=t18170115-64">trial of five men for the theft of weapons from a house</a> during the Spa Fields riots in 1816. For similar sources for post 1913 trials, see <a href="/data/about/locating-information-about-old-bailey-trials-after-1913/">Locating Information about Old Bailey after 1913</a>.</p>
                    <h2 id="pardons">Petitions and Pardons</h2>
                    <p>There were occasions in the course of the legal process when defendants might wish to petition the court about the conduct of their trial. Most importantly, convicted criminals often petitioned for a <a href="/data/about/punishment/#notpunished-pardon">pardon</a> or to have their punishment reduced, particularly if they had been sentenced to death, and often their friends, relatives, and neighbours sent petitions in support of their plea. This was an important exercise, and frequently successful: 43 percent of capital convicts were pardoned between 1730 and 1779, rising to 89 percent in the 1830s. Even after the reform of the Bloody Code restricted capital punishment to murder and a few other serious offences, the pardon rate remained high, at 70 percent. Petitions for pardons and to remit sentences were typically addressed to the monarch in the eighteenth century, and after 1837 to ministers. Officials then asked for a report on the case before it was discussed by the monarch and/or ministers. During the nineteenth century the developing bureaucracy within the Home Office played an ever-increasing role in these discussions and decision-making. These different processes generated some valuable records.</p>
                    <p>Petitions addressed to the courts may be found in the <a href="https://www.cityoflondon.gov.uk/things-to-do/history-and-heritage/london-metropolitan-archives">London Metropolitan Archives</a>. Petitions addressed to the monarch or ministers, together with judges’ reports on such cases and records of decisions and pardons, are kept at <a className="urllink" href="http://www.nationalarchives.gov.uk/" rel="nofollow">The National Archives</a>.</p>
                    <h2 id="calendarsofprisoners">Calendars of Prisoners</h2>
                    <p>Keepers of <a href="https://www.londonlives.org/static/Prisons.jsp#toc15">Newgate Prison</a>, where defendants were kept before their Old Bailey trials, were required to keep records of the prisoners in their custody, from the moment they were committed to await trial to the time they were discharged, either because they had been exonerated or in order to undergo their punishment. Included in the <a className="urllink" href="https://www.oldbaileyonline.org/forms/formAssociatedRecords.jsp" rel="nofollow">database of <strong>Associated Records</strong></a> are references to the calendars of this prison for 1693 to 1707 and 1791 to 1834, while for cases from 1782 to 1913, links are provided from the Old Bailey trial accounts to transcripts of the relevant records provided on the <a href="http://digitalpanopticon.org">Digital Panopticon</a> website. [NB the Associated Records search does not seem to be working well–I can’t find these links to the Newgate Calendar]
                    </p><p>For the period 1693 to 1707 the calendars provide evidence of the prisoners’ names, why they were there, and in which part of the prison they lodged (and how much the lodging cost).</p>
                    <p>For the period from 1791, the information comes from either the <a href="https://www.digitalpanopticon.org/Newgate_Calendars_of_Prisoners_1782-1931">Newgate Calendars</a> or the <a href="https://www.digitalpanopticon.org/England_and_Wales_Criminal_Registers_1791-1892">Criminal Registers</a>, both kept in The National Archives.&nbsp; As an example of the greater interest in documenting the personal characteristics of the accused which developed from the late eighteenth century, much more&nbsp; information is often provided in the Criminal Registers, including some or all of the following: age, height, eye colour, hair colour, physical description, occupation, place of birth, offence, by whom committed, date of trial at the Old Bailey, trial sentence, and when and how the sentence was executed. The information about sentencing is valuable since it includes evidence of pardons and conditional pardons issued after the <strong>Proceedings</strong> were published. The registers also include annual name indexes and summaries of the total numbers of prisoners executed, pardoned, transported, whipped, etc.</p>
                    <h2 id="refuge">London Refuge for the Destitute</h2>
                    <p>Founded in 1806 as a charity for poor able-bodied men and women, in the 1810s and 1820s the Refuge was used by the Old Bailey judges as a reformatory for young convicts who they felt should not be corrupted by commitment to prison. Some convicts were sentenced directly to the Refuge (and can be found by <a className="urllink" href="https://www.oldbaileyonline.org/forms/formMain.jsp" rel="nofollow">searching</a> for <strong>Imprisonment: Other Institution</strong> as a punishment type), while others were sent by a more discrete practice in which the convict was fined one shilling and then privately handed over to the Refuge (without this being documented in the <strong>Proceedings</strong>).</p>
                    <p>Upon application for admission to the refuge, the convicts were interviewed by a committee and their biographical details recorded. These short life narratives provide valuable details of the lives of the poor. Together with the committee’s decision, they are recorded in the minute books of the Refuge kept at the <a href="https://hackney.gov.uk/archives">Hackney Archives Department</a>. 495 cases from between 1812 and 1833 have been transcribed, linked to the relevant Old Bailey trial where possible, and included in the <strong><a className="urllink" href="https://www.oldbaileyonline.org/forms/formAssociatedRecords.jsp" rel="nofollow">Associated Records Database.</a></strong></p>
                    <h2 id="punishment">Punishment Records</h2>
                    <div className="bad"><div id="attachment_8149" className="wp-caption aligncenter"><img className="img-fluid" srcSet="https://www.dhi.ac.uk/blogs/old-bailey/wp-content/uploads/sites/35/2022/03/Convicts-off-Woolwicha-300x200.jpg 300w, https://www.dhi.ac.uk/blogs/old-bailey/wp-content/uploads/sites/35/2022/03/Convicts-off-Woolwicha-1024x683.jpg 1024w, https://www.dhi.ac.uk/blogs/old-bailey/wp-content/uploads/sites/35/2022/03/Convicts-off-Woolwicha-768x512.jpg 768w, https://www.dhi.ac.uk/blogs/old-bailey/wp-content/uploads/sites/35/2022/03/Convicts-off-Woolwicha-1536x1024.jpg 1536w, https://www.dhi.ac.uk/blogs/old-bailey/wp-content/uploads/sites/35/2022/03/Convicts-off-Woolwicha-624x416.jpg 624w, https://www.dhi.ac.uk/blogs/old-bailey/wp-content/uploads/sites/35/2022/03/Convicts-off-Woolwicha.jpg 1800w" src="http://www.dhi.ac.uk/blogs/old-bailey/wp-content/uploads/sites/35/2022/03/Convicts-off-Woolwicha-300x200.jpg"/><p id="caption-attachment-8149" className="wp-caption-text">Carver &amp; Bowles (publishers), ‘A view near Woolwich in Kent, shewing the Employment of the convicts from the Hulks’ (1779). NMG PAJ0774. © Trustees of the National Maritime Museum at Greenwich.</p></div></div>
                    <p>The <strong>Old Bailey Proceedings</strong> only irregularly provide evidence of the sentences actually imposed on convicts. Although a sentence is recorded, owing to the large number of <a href="/data/about/punishment/#notpunished-pardon">pardons</a>, many received different (or no) punishments.&nbsp; <a href="/data/about/punishment/#actual">Other records can be consulted to determine the punishments convicts actually received</a>. These records also contain vital information about the personal characteristics of convicts and their lives following their trials.&nbsp; As further evidence of the growing interest taken by public officials in the personal characteristics of convicts, some of these records, particularly of those who were transported to Australia, are very detailed.</p>
                    <p>A large number of the most important of these records have been assembled in the <a href="http://digitalpanopticon.org">Digital Panopticon</a> and, where such evidence exists, links are provided from the Old Bailey trial accounts on this website to the relevant “life archives” of individual convicts in the Digital Panopticon. Included among the records which can be accessed in this way are:</p>
                    <div className="center image"></div>
                    <ul>
                    <li>
                    <ul>
                    <li>
                    <ul>
                    <li>Records of the <strong>executions</strong>, <strong>pardons</strong>, or other punishments of those who were sentenced to death between 1760 and 1837, available through the <a href="https://www.digitalpanopticon.org/Capital_Convictions_at_the_Old_Bailey_1760-1837">Capital Convictions at the Old Bailey Database</a>.</li>
                    <li><a href="https://www.digitalpanopticon.org/British_Transportation_Registers_1787-1867">Registers of those <strong>Transported to Australia</strong> between 1787 and 1867</a>, together with records compiled in the colonies of the convicts’ behaviour in Australia.&nbsp; Because Australia was, in effect, an open prison, detailed records were kept in order to keep track of convicts, including regular censuses.&nbsp; The richest of these records pertain to those transported to Tasmania, and are available through <a href="https://www.digitalpanopticon.org/VDL_Founders_and_Survivors_Convicts_1802-1853">Van Diemen’s Land Founders and Survivors Convicts 1802-1853</a>, a consolidated database of evidence from numerous original sources.</li>
                    <li><a href="https://www.digitalpanopticon.org/Hulks_Registers_1801-1879">Registers of the male prisoners awaiting transportation who were kept in aging ships, known as <strong>the hulks</strong></a>, in English rivers and ports until their boat was ready for the trip to Australia.&nbsp; Like the transportation records, these include detailed evidence of the prisoners’ personal characteristics.</li>
                    <li>Calendars and registers of convicts whose punishment involved a period of <strong><a href="https://www.digitalpanopticon.org/Imprisonment">imprisonment</a></strong> in Britain, including those <a href="https://www.digitalpanopticon.org/UK_Licences_for_the_Parole_of_Convicts_1853-1925">released on parole</a>.&nbsp; Some of the most detailed of these are the <a href="https://www.digitalpanopticon.org/Metropolitan_Police_Register_of_Habitual_Criminals_1881-1925">Metropolitan Police Registers of Habitual Criminals, 1881-1925</a>.</li>
                    </ul>
                    </li>
                    </ul>
                    </li>
                    </ul>
                    <h2 id="literature">Novels, Satirical Prints and Popular Entertainment</h2>
                    <div className="center image"></div>
                    <div className="bad"><div id="attachment_2839" className="wp-caption aligncenter"><img className="img-fluid" srcSet="https://www.dhi.ac.uk/blogs/old-bailey/wp-content/uploads/sites/35/2022/03/Harlots-Progress-BM-300x243.jpg 300w, https://www.dhi.ac.uk/blogs/old-bailey/wp-content/uploads/sites/35/2022/03/Harlots-Progress-BM-1024x828.jpg 1024w, https://www.dhi.ac.uk/blogs/old-bailey/wp-content/uploads/sites/35/2022/03/Harlots-Progress-BM-768x621.jpg 768w, https://www.dhi.ac.uk/blogs/old-bailey/wp-content/uploads/sites/35/2022/03/Harlots-Progress-BM-1536x1242.jpg 1536w, https://www.dhi.ac.uk/blogs/old-bailey/wp-content/uploads/sites/35/2022/03/Harlots-Progress-BM-2048x1656.jpg 2048w, https://www.dhi.ac.uk/blogs/old-bailey/wp-content/uploads/sites/35/2022/03/Harlots-Progress-BM-624x505.jpg 624w" src="http://www.dhi.ac.uk/blogs/old-bailey/wp-content/uploads/sites/35/2022/03/Harlots-Progress-BM-300x243.jpg"/><p id="caption-attachment-2839" className="wp-caption-text">William Hogarth, A Harlot’s Progress, plate 3 (1732). © Trustees of the British Museum</p></div></div>
                    <p>The detailed narratives of crimes found in criminal biographies, trial reports, and other published and unpublished records were an important source of material for the authors of fictional representations.&nbsp; A surprising proportion of <strong>novels</strong>, a popular new genre in the early eighteenth century, include accounts of crime. While these works cannot be linked to individual trials (and are therefore not included in the <strong>Associated Records Database)</strong>, they can provide valuable evidence for historians. Some, most famously Daniel Defoe’s <strong>Moll Flanders</strong> (1722), were partially based on real-life criminals.&nbsp; There was a cluster of such novels in the early nineteenth century, including William Harrison Ainsworth’s <strong>Rookwood</strong> (1834, which recounts Dick Turpin’s mythical ride to York) and <strong>Jack Sheppard</strong> (1839). Charles Dickens’s character Fagin (in <strong>Oliver Twist</strong>, 1839) is often said to have been based on the noted receiver Isaac “Ikey” Solomon who was tried at the Old Bailey in <a className="urllink" href="https://www.oldbaileyonline.org/browse.jsp?div=t18100606-89" rel="nofollow">1810</a> and several times in the <a href="https://www.oldbaileyonline.org/search.jsp?form=searchHomePage&amp;_divs_fulltext=&amp;kwparse=and&amp;_persNames_surname=solomon&amp;_persNames_given=isaac&amp;_persNames_alias=&amp;_offences_offenceCategory_offenceSubcategory=&amp;_verdicts_verdictCategory_verdictSubcategory=&amp;_punishments_punishmentCategory_punishmentSubcategory=&amp;_divs_div0Type_div1Type=&amp;fromMonth=&amp;fromYear=1830&amp;toMonth=&amp;toYear=1830&amp;ref=&amp;submit.x=40&amp;submit.y=13">summer of 1830</a>. While these entertaining works cannot be relied upon for specific information about these men’s crimes, they provide evidence of contemporary ways of thinking about crime and remain well worth reading.</p>
                    <div className="center image"></div>
                    <p>Crime was also a frequent subject matter of <strong>contemporary engravings and prints</strong>, particularly in the eighteenth century, as many of the illustrations on this website attest. There was a flourishing market in these works, particularly in London, which was their main focus. They could appeal to illiterate and semi-literate audiences, but the frequent inclusion of text suggests that their primary audience was educated. The representations of crime found in these prints are selective, but they are revealing of contemporary attitudes. The crimes of the rich and famous, and more sensational crimes, were depicted while the ordinary thefts which make up the vast majority of Old Bailey trials were ignored. Many prints were morality tales, but the rich details in some, notably those of William Hogarth (in particular in <strong>A Harlot’s Progress</strong>, 1732, and&nbsp; <strong>Industry and Idleness</strong>, 1747), contained different levels of meaning, criticising the behaviour of the judicial authorities as well as that of the idle and immoral poor. Although many prints celebrated the virtues of English law, there were frequent criticisms of lawyers, watchmen, and the police. These sources provide a fascinating commentary on crime and justice in the metropolis.</p>
                    <p>Lower-class Londoners, including those who could not read, had greater access to <strong>ballads</strong>, short verse narratives which were performed orally on the street and could be purchased as printed broadsides.&nbsp; While more popular in the early modern period, ballads continued to flourish throughout the eighteenth and nineteenth centuries, and they provide important evidence of popular attitudes towards crime and punishment.&nbsp; Included among the wide array of topics covered was a variety of crimes, particularly murder and highway robbery, with the latter sometimes celebrating the highwayman as a hero.&nbsp; While many of these tales were fictional, some had a basis in real life crimes, and some can be linked to Old Bailey trials.&nbsp; The “Copy of Verses, Made Upon the Unfortunate Beauty, Miss Elizabeth Taylor, now under sentence of death at Newgate, for Robbing her Master”, for example, followed Taylor’s <a href="https://www.oldbaileyonline.org/browse.jsp?div=t17850629-67">1785 trial for burglary</a>. Like all other fictional or semi-fictional narratives of crime, however, ballads are not included in the <strong>Associated Records Database.</strong></p>
                    <p>Facilitated by growing literacy and lower printing costs, the literature of crime further expanded in the nineteenth century, and became more violent. “Penny Bloods”, cheap fiction sold in instalments, featured depravity and violence in titles such as <strong>The Mysteries of London</strong> (1844-45), created by the Chartist G.W.M. Reynolds, and <strong>The String of Pearls</strong> (1846-47, featuring the murderous butcher&nbsp;<strong>Sweeney Todd</strong>).&nbsp; Crimes were also performed in street theatre such as <strong>Punch and Judy</strong> shows, and in melodramatic stage productions. There is a fascinating juxtaposition between the popularity of these bloody and violent genres and the <a href="/data/about/patterns-of-crime-and-prosecution/#decline">apparent decline of actual violent crime</a> in nineteenth-century London.</p>
                    <h2 id="using">Using the Associated Records 1674-1834 Database</h2>
                    <p>The<strong> Associated Records Database</strong> principally covers the period between 1674 and 1834, though it includes some later printed ephemera.&nbsp; Where we have been able to link records in this database with specific trials, this is indicated at the top of the webpage containing the trial account. Clicking on this link will take you to specific information about the Associated Records for that trial. Please be aware that the linkages between the trials and the Associated Records have been created by a largely automated process and are subject to a significant degree of error.</p>
                    <p>Furthermore, some 25 per cent of the records in the <strong>Associated Records Database</strong> have not been linked to trials.&nbsp; It is possible to <a href="https://www.oldbaileyonline.org/forms/formAssociatedRecords.jsp">search this database</a> separately by name, offence, type of document, library or archive, and date.</p>
                    <p>It is important to note that this database of 25,000 records, while extensive and covering the most important classes of records and publications, is not comprehensive. We have not been able to identify all associated records, particularly at <a href="https://www.nationalarchives.gov.uk/">The National Archives</a>, where parts of its vast collection of records have not yet been catalogued in detail. Additional information is also available in other printed sources which we have not catalogued, notably <a href="#newspapers">newspapers</a>.</p>
                    <p>Following up the references provided in this database, therefore, should be the start of a process of research and discovery, not the end!</p>
                    <h2 id="libraries">Locations of Documents included in the Associated Records Database</h2>
                    <p>As indicated in the references provided, books and documents in the <strong>Associated Records Database</strong> are located in one of the following libraries, archives, or microfilm/fiche collections. All are in London unless otherwise noted.</p>
                    <ul>
                    <li>
                    <ul>
                    <li>
                    <ul>
                    <li>All Soul’s College, Oxford</li>
                    <li>Bank of England Archives</li>
                    <li>Bishopsgate Institute</li>
                    <li>Bodleian Library, Oxford</li>
                    <li>British Library</li>
                    <li>Chadwyck Healey Microfiche Collection, “British Trials, 1660-1900”. This collection is available in some major libraries, and The National Archives. Titles may be available online.</li>
                    <li>Glasgow University Library, Glasgow</li>
                    <li>Guildhall Library</li>
                    <li>Hackney Archives</li>
                    <li>Inner Temple Library</li>
                    <li>John Johnson Collection of Printed Ephemera (Bodleian Library, Oxford and <a href="http://johnjohnson.chadwyck.co.uk/marketing.do">online by subscription</a>)</li>
                    <li>John Rylands Library, Manchester</li>
                    <li>Lincoln’s Inn Library</li>
                    <li>London Metropolitan Archives</li>
                    <li>The National Archives</li>
                    <li>National Library of Scotland, Edinburgh</li>
                    <li>Post Office Archives</li>
                    <li>Research Publications Inc. Microfilm Collection, “The Eighteenth Century”. These titles are available by subscription in <a href="https://www.gale.com/intl/primary-sources/eighteenth-century-collections-online">Eighteenth-Century Collections Online</a>.</li>
                    <li>Senate House Library, University of London</li>
                    <li>University Microfilms International, Microfilm Collection “Early English Books. 1641-1700”. These titles are available by subscription&nbsp;in <a href="https://about.proquest.com/en/products-services/eebo/">Early English Books Online</a>.</li>
                    <li>Westminster Archives Centre</li>
                    </ul>
                    <ul className="pagenav">
                    <li><a href="#top">Back to Top</a></li>
                    <li className="last"><a href="#reading">Introductory Reading</a></li>
                    </ul>
                    </li>
                    </ul>
                    </li>
                    </ul>
                    <h2 id="digitalpanopticon">Records in the Digital Panopticon, 1740 to 1925</h2>
                    <p>Also included in the summary box at the top of trial display pages are links to records in the <a href="http://www.digitalpanopticon.org">Digital Panopticon</a>, a compendium of fifty datasets containing millions of records, many of which are linked to the 90,000 men and women convicted at the Old Bailey between 1740 and 1925.&nbsp; These records, which concern their pre-trial imprisonment, <a href="#punishment">punishments actually received</a>, poor relief , entries in the census, and deaths, are organised into “life archives” for each convict.</p>
                    <p>While those links were created by automated record-linkage and are subject to a degree of error, you can also <a href="https://www.digitalpanopticon.org/search">search the Digital Panopticon directly</a> to find potential additional evidence about those tried at the Old Bailey (whether or not they were convicted).</p>
                    <h2 id="reading" className="divider">Introductory Reading</h2>
                    <ul>
                    <li>
                    <ul>
                    <li>
                    <ul>
                    <li>Bell, I. A., <strong>Literature and Crime in Augustan England</strong> (London, 1991)</li>
                    <li>Crone, Rosalind, <strong>Violent Victorians: Popular Entertainment in Nineteenth-Century London</strong> (Manchester, 2012)</li>
                    <li>Devereaux, Simon, “From Sessions to Newspaper? Criminal Trial Reporting, the Nature of Crime, and the London Press, 1770-1800”, <strong>London Journal</strong> 32, no. 1 (2007), 1-27</li>
                    <li>Gatrell, V. A. C., <strong>The Hanging Tree: Execution and the English People 1770-1868</strong> (Oxford, 1994), chs 4-5</li>
                    <li>Hawkins, David T. <strong>Criminal Ancestors: A Guide to Historical Criminal Records in England and Wales</strong> (Stroud, 1992)</li>
                    <li>Gladfelder, Hal, <strong>Criminality and Narrative in 18th Century England: Beyond the Law</strong> (London, 2001)</li>
                    <li>King, Peter, “Newspaper Reporting and Attitudes to Crime and Justice in late Eighteenth and Early Nineteenth-Century London”, <strong>Continuity and Change</strong> 22 (2007), 73-112</li>
                    <li>McKenzie, A., <strong>Tyburn’s Martyrs: Execution in England 1675-1775</strong> (London, 2007)</li>
                    <li>Rawlings, Philip, <strong>Drunks, Whores and Idle Apprentices: Criminal Biographies of the Eighteenth Century</strong> (London, 1992)</li>
                    <li>Rowbotham, J., Stevenson, K., and Pegg, S., <strong>Crime News in Modern Britain: Press Reporting and Responsibility, 1820-2010&nbsp;</strong>(London, 2013)</li>
                    <li>Sharpe, J. A., ed., <strong>Crime and the Law in English Satirical Prints, 1600-1832</strong> (Cambridge, 1986)</li>
                    <li>Shoemaker, R. and Ward, R., “Understanding the Criminal: Record-Keeping, Statistics and the Early History of Criminology in England”, <strong>British Journal of Criminology</strong>, 57:6 (2014), 442-1461</li>
                    </ul>
                    </li>
                    </ul>
                    </li>
                    </ul>
                    <p>For more secondary literature on this subject see the <a href="/data/about/proceedingsbibliography/">Bibliography</a>.</p>
                    <ul>
                    <li>
                    <ul>
                    <li>
                    <ul className="pagenav">
                    <li><a href="#top">Back to Top</a></li>
                    </ul>
                    </li>
                    </ul>
                    </li>
                    </ul>
                    <p>This page was written by Robert Shoemaker, with help from other members of the Old Bailey project team.&nbsp; The database of <strong>Associated Records</strong> was created by Mary Clayton.</p>
                    </div></div>
                    </div>
                    <div className="col-3">
                        <div id="post-toc"><ul><li><a href="#popularliterature">Introduction: Crime Literature and Judicial Records</a></li><li><a href="#lastdyingspeeches">Last Dying Speeches</a></li><li><a href="#biographies">Criminal Biographies</a></li><li><a href="#compilations">Compilations of Trials</a></li><li><a href="#newspapers">Newspapers</a></li><li><a href="#courtrecords">Court Records: Examinations, Depositions, Informations, Prosecution Briefs</a></li><li><a href="#pardons">Petitions and Pardons</a></li><li><a href="#calendarsofprisoners">Calendars of Prisoners</a></li><li><a href="#refuge">London Refuge for the Destitute</a></li><li><a href="#punishment">Punishment Records</a></li><li><a href="#literature">Novels, Satirical Prints and Popular Entertainment</a></li><li><a href="#using">Using the Associated Records 1674-1834 Database</a></li><li><a href="#libraries">Locations of Documents included in the Associated Records Database</a></li><li><a href="#digitalpanopticon">Records in the Digital Panopticon, 1740 to 1925</a></li><li><a href="#reading">Introductory Reading</a></li></ul></div>
                    </div>
                </div>
            </div>
            */}

        </>

    );

};

export default About;
